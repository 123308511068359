import React from 'react'
import BeatLoader from 'react-spinners/BeatLoader'

const Loader = ({
  message = 'You must be registered to view this page',
  color = 'darkBlue',
}) => {
  return (
    <div className="flex items-center justify-center width-full height-full py-96">
      <div className="flex flex-col">
        <div className="flex justify-center mb-10">
          <BeatLoader size={20} margin={5} color={color} />
        </div>
        <h2 className="text-center uppercase text-l">{message}</h2>
      </div>
    </div>
  )
}

export default Loader
