import { navigate } from 'gatsby'
import { useAuth } from 'contexts/AuthContext'
import { useState, useEffect } from 'react'

const useNeedsMembership = (needsMembership = true) => {
    const [showLoaderPage, setShowLoaderPage] = useState(false);
    const { currentUser, loading } = useAuth()

    useEffect(() => {
        if (needsMembership && !loading && currentUser === null) {
            navigate('/sign-in')
        }
    }, [currentUser, loading, needsMembership])

    useEffect(() => {
        const showLoaderPage =
            (needsMembership && loading) ||
            (needsMembership && !loading && !currentUser)
        setShowLoaderPage(showLoaderPage)
    }, [currentUser, loading, needsMembership])


    return showLoaderPage
}

export default useNeedsMembership

