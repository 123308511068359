import {
  Box,
  Button,
  ChakraProvider,
  Flex,

  // MODAL
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { Next, PageGroup, Paginator, Previous } from 'chakra-paginator'
import { useAuth } from 'contexts/AuthContext'
import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FormElement } from 'starterComponents'
import { Container } from 'starterUiComponents'
import ApiFetch from '../../utils/api'
import useNeedsMembership from "../../hooks/useNeedsMembership"
import Loader from "../../components/ui-components/Loader"

const DEFAULT_PAGE_SIZE = 30
const STATUSES = {
  FETCHING: 'fetching',
  SUCCESS: 'success',
  ERROR: 'error',
  SUBMITTING: 'submitting',
  SUBMITTED: 'submitted',
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetch':
      return {
        ...state,
        calls: [],
        total: 0,
        status: 'fetching',
        error: null,
        page: action.payload.page,
        // pageCount: 0,
        filter: action.payload.filter,
        sort: action.payload.sort,
      }
    case 'success':
      return {
        ...state,
        calls: action.payload.data,
        total: action.payload.count,
        status: 'success',
        error: null,
        pageCount: Math.ceil(action.payload.count / DEFAULT_PAGE_SIZE),
      }
    case 'error':
      return {
        ...state,
        status: 'error',
        error: action?.payload?.error ?? null,
      }
    case 'set_filter_by':
      return {
        ...state,
        filter: action.payload.filter,
      }
    case 'submitting':
      return {
        ...state,
        status: 'submitting',
      }
    case 'submitted':
      return {
        ...state,
        status: 'success',
      }
    default:
      throw new Error(`CallHistory - Unssupported action type ${action.type}`)
  }
}
const CallHistoryPage = ({ user }) => {
  const { currentUser, loading } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [selectedCall, setSelectedCall] = useState(null)

  const newCommentsRef = useRef(null)

  const onGoToPage = async (page) => {
    await fetchCallHistory(page)
  }

  const tableRef = React.useRef(null)
  const rowRef = React.useRef(null)
  const containerRef = React.useRef(null)
  const fakeScrollRef = React.useRef(null)
  const [rowWidth, setRowWidth] = useState(null)
  const prevScrollX = useRef(0);

  let scrolling = false;
  const onScrollFake = (e) => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    let goingRight = false;
    const currentScrollX = e.target.scrollLeft;
    if (prevScrollX.current < currentScrollX && goingRight) {
      goingRight = false;
    }
    if (prevScrollX.current > currentScrollX && !goingRight) {
      goingRight = true;
    }
    prevScrollX.current = currentScrollX;
    containerRef.current.scrollLeft = currentScrollX * (goingRight ? -1 : 1);
  };

  const onScrollReal = (e) => {
    if (scrolling) {
      scrolling = false;
      return true;
    }
    scrolling = true;
    fakeScrollRef.current.scrollLeft = containerRef.current.scrollLeft;
  };

  const [{ calls, status, error, page, pageSize, pageCount }, dispatch] =
    React.useReducer(reducer, {
      status: 'idle',
      error: null,
      calls: [],
      total: 0,
      page: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      pageCount: 1,
      sort: null,
      filter: null,
    })

  const fetchCallHistory = useCallback(
    async (page = 1, options = {}) => {
      const URL = `/techcalls`
      if (options.sort || options.filter) {
        page = 1
      }
      dispatch({
        type: 'fetch',
        payload: {
          page,
          sort: options?.sort ?? null,
          filter: options?.filter ?? null,
        },
      })
      try {
        const sub_num = currentUser?.shopData?.sub_num
        let fetchUrl = `${URL}/shopsubnum/${sub_num}?page=${page}&pageSize=${pageSize}`
        if (options.sort) {
          fetchUrl += `&sort_by=${options.sort.order}${options.sort.sortBy}`
        }
        if (options.filter) {
          fetchUrl += `&${options.filter.filterKey}=${options.filter.filterValue}`
        }
        const response = await ApiFetch({
          path: fetchUrl,
          userToken: currentUser?.shopData?.idToken,
        })

        if (response.data) {
          dispatch({ type: 'success', payload: response })
        }
      } catch (error) {
        console.error('[CallHistory][fetchCallHistory] error: ', error.message)
        dispatch({ type: 'error', payload: { error: error.message } })
      }
    },
    [currentUser?.shopData?.idToken, pageSize, currentUser?.shopData?.sub_num]
  )

  useEffect(() => {
    if (!loading && currentUser === null) {
      navigate('/sign-in')
    }
  }, [currentUser, loading])

  useEffect(() => {
    if (currentUser && currentUser.shopData) {
      fetchCallHistory()
    }
  }, [currentUser, fetchCallHistory])
  // styles
  const baseStyles = {
    w: 7,
    fontSize: 'sm',
  }

  const normalStyles = {
    ...baseStyles,
    bg: 'grey.300',
  }

  const activeStyles = {
    ...baseStyles,
    // bg: "green.300",
    bg: 'rgba(70,97,137,1)',
    color: 'white',
  }

  const separatorStyles = {
    w: 7,
    bg: 'rgba(70,97,137,1)',
    color: 'white',
  }

  const onRequestCallBack = async () => {
    if (selectedCall) {
      try {
        dispatch({ type: 'submitting' })
        const response = await ApiFetch({
          method: 'POST',
          path: `/techcalls/request-callback`,
          body: {
            previousCallId: selectedCall.id,
            newComment: newCommentsRef?.current?.value ?? null,
          },
          userToken: currentUser?.shopData?.idToken,
        })

        if (response.code === 200) {
          dispatch({ type: "submitted" })
        } else if (response.errors && response.errors.length) {
          dispatch({
            type: "validationErrors", payload: {
              validationErrors: response.errors
            }
          })
        }
      } catch (error) {
        dispatch({
          type: "error", payload: {
            error: error.message
          }
        })
      } finally {
        dispatch({ type: 'submitted' })
      }

      fetchCallHistory()
      onClose()
    }
  }

  const openRequestCallBackPopup = (call) => {
    setSelectedCall(call)
    onOpen()
  }
  React.useEffect(() => {
    setTimeout(() =>
      setRowWidth(tableRef?.current?.offsetWidth || 0)
      , [500])
  }, [tableRef?.current?.offsetWidth])

  const showLoaderPage = useNeedsMembership()
  if (showLoaderPage) {
    return <Loader />
  }

  return (
    <Box style={{ width: "75%", margin: "0 auto" }}>
      {status === STATUSES.ERROR && <div>An errror happened {error}</div>}
      <div
        ref={fakeScrollRef}
        onScroll={onScrollFake}
        style={{
          width: `100%`,
          overflowX: "auto",
          overflowY: "hidden",
          padding: "0px",
          margin: "0",
        }}
      >
        <div style={{
          width: `${rowWidth}px`,
          height: "20px"
        }} id="internal-fake-scroll-div">
        </div>
      </div>

      <div
        ref={containerRef}
        onScroll={onScrollReal}
        style={{
          width: "100%",
          margin: "0",
          display: "flex",
          flexDirection: "column",
          overflowX: "auto",
          overflowY: "hidden",
          padding: "0px"
        }}
      >

        <Table variant="simple" ref={tableRef} id="table">
          <Thead>
            <Tr
              ref={rowRef}
              sx={{
                th: {
                  fontSize: 14,
                  bg: 'light',
                  color: 'darkGrey',
                  width: 'auto',
                  borderRight: '1px solid #ddd',
                  borderLeft: '1px solid #ddd',
                },
              }}
            >
              <Th>Request Callback</Th>
              <Th>Year</Th>
              <Th>Make</Th>
              <Th>Model</Th>
              <Th>Engine</Th>
              <Th>Other</Th>
              {/* <Th>Vin?</Th> */}
              <Th>TransType</Th>
              <Th>Shop Tech</Th>
              <Th>ATSG Tech Requested</Th>
              <Th>Date Taken</Th>
              <Th>Time Taken</Th>
              <Th>Problem Description</Th>
              <Th>Comments</Th>
              <Th>Current Status</Th>
            </Tr>
          </Thead>
          <Tbody role="rowgroup">
            {calls &&
              calls.map((call) => (
                <Tr
                  key={call.id}
                  sx={{
                    td: {
                      borderRight: '1px solid #ddd',
                      borderLeft: '1px solid #ddd',
                      fontFamily: 'heading',
                      fontWeight: 'light',
                    },
                  }}
                >
                  <Td>
                    <Button
                      variant="lightBlue"
                      width="auto"
                      fontWeight="bold"
                      onClick={() => openRequestCallBackPopup(call)}
                    >
                      CALLBACK
                    </Button>
                  </Td>
                  <Td>{call.year}</Td>
                  <Td>{call.make}</Td>
                  <Td>{call.model}</Td>
                  <Td>{call.engine}</Td>
                  <Td>{call.other}</Td>
                  {/* VIN? */}
                  <Td>{call.trans}</Td>
                  <Td>{call.technam}</Td>
                  <Td>{call.atsgtechreq}</Td>
                  <Td>{call.takendate} </Td>
                  <Td>{call.takentime} </Td>
                  <Td>{call.probstring}</Td>
                  <Td minWidth={350}>{call.comments}</Td>
                  <Td>{call.status}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </div>
      <Container className="mt-10">
        <ChakraProvider>
          <Paginator
            isDisabled={pageCount === 1}
            currentPage={page}
            pagesQuantity={pageCount}
            onPageChange={onGoToPage}
            activeStyles={activeStyles}
            innerLimit={1}
            outerLimit={2}
            normalStyles={normalStyles}
            separatorStyles={separatorStyles}
          >
            <Flex justify="space-between" pb={5}>
              <Previous bg="rgba(70,97,137,1)" color="white">
                Previous
              </Previous>
              <PageGroup isInline align="center" />
              <Next bg="rgba(70,97,137,1)" color="white">
                Next
              </Next>
            </Flex>
          </Paginator>
        </ChakraProvider>
      </Container>

      <Modal
        size="3xl"
        isOpen={isOpen}
        onClose={onClose}
        py="40px !important"
        pl={20}
        className="px-20 py-10"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="font-bold text-white bg-blue text-md font-heading">
            Request A Callback
          </ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            {selectedCall && (
              <form action="">
                <ModalFormEl
                  name="year"
                  label="Year"
                  value={selectedCall.year}
                />
                <ModalFormEl
                  name="make"
                  label="Make"
                  value={selectedCall.make}
                />
                <ModalFormEl
                  name="model"
                  label="Model"
                  value={selectedCall.model}
                />
                <ModalFormEl
                  name="engine"
                  label="Engine"
                  value={selectedCall.engine}
                />
                <ModalFormEl
                  name="other"
                  label="Other"
                  value={selectedCall.other}
                />
                {/* <ModalFormEl name="VIN" label="VIN" value={selectedCall.VIN} /> */}
                <ModalFormEl
                  name="trans"
                  label="TransType"
                  value={selectedCall.trans}
                />
                <ModalFormEl
                  name="atsgtechreq"
                  label="Shop Tech Name"
                  value={selectedCall.atsgtechreq}
                />
                <ModalFormEl
                  name="technam"
                  label="ATSG Tech Assigned"
                  value={selectedCall.technam}
                />
                <ModalFormEl
                  name="takendate"
                  label="Date Taken"
                  value={selectedCall.takendate}
                />
                <ModalFormEl
                  name="takentime"
                  label="Time Taken"
                  value={selectedCall.takentime}
                />
                <ModalFormEl
                  name="probstring"
                  label="Problem Description"
                  value={selectedCall.probstring}
                />
                <ModalFormEl
                  name="comments"
                  label="Comments"
                  value={selectedCall.comments}
                />
                <ModalFormEl
                  name="status"
                  label="Current Status"
                  value={selectedCall.status}
                />
                <FormElement
                  id="comments"
                  label="Comments"
                  sx={{
                    mt: 8,
                    label: {
                      mt: 3,
                      fontSize: 'base',
                      textAlign: 'left',
                      width: 'auto',
                      // fontFamily: 'body!important',
                    },
                  }}
                >
                  <Textarea
                    ref={newCommentsRef}
                    placeholder="Comments"
                    height="204px"
                  />
                </FormElement>
              </form>
            )}
          </ModalBody>

          <ModalFooter>
            {status !== STATUSES.SUBMITTING && (
              <Button
                variant="lightBlue"
                width="auto"
                mr={3}
                onClick={onRequestCallBack}
              >
                Submit
              </Button>
            )}
            {status === STATUSES.SUBMITTING && (
              <Button
                variant="lightBlue"
                width="auto"
                mr={3}
                isLoading
                loadingText="submitting"
              >
                Submit
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box >
  )
}

const ModalFormEl = ({ label, value }) => (
  <div className="mb-2 font-body">
    <strong>{label}:</strong> <span>{value}</span>
  </div>
)

export default CallHistoryPage
